import React from 'react'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { Button } from '@pancakeswap/uikit'
import { variants } from '@pancakeswap/uikit/src/components/Button/types'
import { useRouter } from 'next/router'

const Home: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()

  const router = useRouter()

  return (
    <HomeWrapper>
      <div className='bg1'>
        <div className='main stop'>
          <div>
            <div className='title'>
              {t('One Stop Cross-chain')}
            </div>
            <div className='simpler desc'>{t('Simpler · faster · Safer')}</div>
            <div>
              <Button style={{ marginRight: 18 }} className='custom'
                      onClick={() => router.push('/swap?outputCurrency=0x1567007264b46dF17519691731B0f3e0751CeD7f&inputCurrency=0x55d398326f99059fF775485246999027B3197955')}>{t('LAUNCH APP')}</Button>
              <Button className='custom'>{t('DOWNLOAD MOBILE APP')}</Button>
            </div>
          </div>

          <div className='cross-img'>
            <img src='/images/home/home/home.png' alt='' />
          </div>
        </div>

        <div className='main'>
          <div className='title'>{t('About longdao Blockchain')}</div>

          <div className='border-box blockchain'>
            <img className='email-img pc-show' src='/images/home/home/email.png' alt='' />

            <p>
              {t('Longdao Blockchain is a platform that allows users to trade, invest, and transact in cryptocurrencies.')}
              <div className='catching'>
                <span>{t('Established in 2017')}</span>
              </div>
              {t(', the company has the backing of renowned investors')}
              <div className='catching'>
                <span>{t('Draper Dragon and Node Capital.')}</span>
              </div>
              {t('With over')}
              <div className='catching'><span>{t('800+ cryptocurrencies')}</span></div>
              {t('available on the platform and access to')}
              <div className='catching'><span>{t('50+ public blockchain networks,')}</span></div>
              {t('longdao Blockchain offers a comprehensive suite of services for crypto users, longdao Blockchain is a trusted partner for anyone looking to engage with the world of cryptocurrencies.')}
            </p>
          </div>
        </div>

        <div className='main out'>
          <div className='title'>{t('Our Products')}</div>

          <div className='border-box longdao-app n1'>
            <div>
              <h3 className='title-color'>{t('Longdao APP')}</h3>
              <p
                className='desc'>{t('After registration, you can use one-click cross-chain swap in the wallet, as well as multiple additional functions including social media red packets, payment, aggregate transactions,group buying, wealth management, mining pools, etc.')}</p>

              <div className='flex justify-between items-center'>
                <Button className='custom'>{t('Download')}</Button>

                <div className='rounded-img mobile-show'>
                  <img src='/images/home/home/daocoApp.png' alt='' />
                </div>
              </div>
            </div>

            <div className='rounded-img pc-show'>
              <img src='/images/home/home/daocoApp.png' alt='' />
            </div>
          </div>

          <div className='border-box longdao-app n2'>
            <div className='rounded-img pc-show'>
              <img src='/images/home/home/bridge.png' alt='' />
            </div>

            <div>
              <h3 className='title-color'>{t('Longdao Allchain Bridge')}</h3>
              <p
                className='desc'>{t('H5 version one-stop cross-chain swap: lt can be directly connected to the user wallet, registration or login required. The swap can be accomplished simply with one-click')}</p>

              <div className='flex justify-between items-center'>
                <Button className='custom'>{t('Launch App')}</Button>

                <div className='rounded-img mobile-show'>
                  <img src='/images/home/home/bridge.png' alt='' />
                </div>
              </div>
            </div>
          </div>

          <div className='border-box longdao-app n3'>
            <div>
              <h3 className='title-color'>{t('Longdao Bridgers')}</h3>
              <p
                className='desc'>{t('A decentralized trading platform currently deployed on multiple public chains (currentlysupporting BSC,Heco, OEC,five EVM chains).It has the characteristics of security.ow transactioncan enjoy ultra-low fees and fasterspeed to completechain or dua-chai')}</p>

              <div className='flex justify-between items-center'>
                <Button className='custom'>{t('Launch App')}</Button>

                <div className='rounded-img mobile-show'>
                  <img src='/images/home/home/bridgers.png' alt='' />
                </div>
              </div>
            </div>

            <div className='rounded-img pc-show'>
              <img src='/images/home/home/bridgers.png' alt='' />
            </div>
          </div>
        </div>

        <div className='featured'>
          <div className='main'>
            <h3 className='white font-impact'>{t('Longdao BLOCKCHAIN HAS BEEN FEATURED ON')}</h3>

            <div className='featured-imgs'>
              <div><img src='/images/home/home/featired-1.png' alt='' /></div>
              <div><img src='/images/home/home/featired-2.png' alt='' /></div>
              <div><img src='/images/home/home/featired-3.png' alt='' /></div>
            </div>

            <div className='featured-imgs'>
              <div><img src='/images/home/home/featired-4.png' alt='' /></div>
              <div><img src='/images/home/home/featired-5.png' alt='' /></div>
            </div>
          </div>
        </div>

        <div className='main news'>
          <div className='title'>
            {t('Latest News')}
          </div>

          <div className='news-wrapper'>
            <div className='border-box'>
              <div className='tw-img'>
                <img src='/images/home/home/tw.png' alt='' />
              </div>
              <h3>{t('EN Twitter')}</h3>
              <p>{t('Tweets by Longdao')}</p>
              <Button className='custom'>{t('FOLLOW')}</Button>
            </div>

            <div className='border-box'>
              <div className='tw-img'>
                <img src='/images/home/home/tw.png' alt='' />
              </div>
              <h3>{t('CN Twitter')}</h3>
              <p>{t('Tweets by Longdao')}</p>
              <Button className='custom'>{t('FOLLOW')}</Button>
            </div>
          </div>
        </div>
      </div>

      <div className='bg2'>
        <div className='main'>
          <div className='largest-box'>
            <div className='title'>
              {t('Largest selection of currencies')}
            </div>
            <LargestItem
              title='Longdao Blockchain products support over 300 cryptocurrencies across Ethereum, BSC, HECO, Polygon, Polkadot, OKExChain and TRON.'
              imgUrl='/images/home/home/largest-1.png'
              label1Text='Over 35,000 direct trading cross-chain pairs'
              label1Icon='/images/home/home/largest-icon-1.png'
              label2Text='Fastest growing selection of cryptocurrencies'
              label2Icon='/images/home/home/largest-icon-2.png'
              label3Text='Multiple blockchains and layer 2 networks'
              label3Icon='/images/home/home/largest-icon-3.png'
            />
          </div>

          <div className='largest-box'>
            <div className='title'>
              {t('Built on a robust and powerful platform')}
            </div>
            <LargestItem
              title='Longdao Blockchain products are built on over four years of cross-chain development experience holding security at its core.'
              imgUrl='/images/home/home/largest-2.png'
              label1Text='Multi-layer security for all transactions'
              label1Icon='/images/home/home/largest-icon-4.png'
              label2Text='Trusted by over 100 API wallet API partners'
              label2Icon='/images/home/home/largest-icon-5.png'
              label3Text='24/7 Dedicated support'
              label3Icon='/images/home/home/largest-icon-6.png'
            />
          </div>

          <div className='largest-box'>
            <div className='title'>
              {t('Best rates')}
            </div>
            <LargestItem
              title='Longdao Blockchain products are programmed to find and select the best rate across diverse set of exchanges, DEX, CEX, bridges, and more!'
              imgUrl='/images/home/home/largest-3.png'
              label1Text='Connected to over 30 CEX and DEX'
              label1Icon='/images/home/home/largest-icon-7.png'
              label2Text='Discover the most efficient trading route'
              label2Icon='/images/home/home/largest-icon-8.png'
              label3Text='Trade safely with fees as low as 0.1%*'
              label3Icon='/images/home/home/largest-icon-9.png'
            />
          </div>
        </div>

        <div className='main fees white'>
          <h2>{t('Even lower fees for loyal supporters')}</h2>
          <p>{t('All wallets that hold 500,000 LONGDAO or more will receive 50% off fees on all transaction on LONGDAO Swap, dropping fees to only 0.1%.')}</p>
          <div>
            <Button variant={variants.WHITE} style={{ marginRight: 16 }}>{t('DETAILS')}</Button>
            <Button variant={variants.WHITE}>{t('SWAP NOW')}</Button>
          </div>

          <img src='/images/home/home/fees.png' className='fees-img' alt='' />
        </div>

        <div className='main app'>
          <div className='title'>
            {t('Unlock more with longdao Mobile App')}
          </div>

          <p
            className='app-desc'>{t('Enjoy numerous unique features such as secure wallet, cross-chain swap, send & receive, red packets, and more!')}</p>

          <div className='flex white'>
            <div className='app-item flex justify-between'>
              <p>{t('Download for iOS')}</p>

              <div style={{ width: 175 }}>
                <div>
                  <Button variant={variants.WHITE} width='100%'>
                    <img src='/images/home/home/appStoreIcon.png' alt='' className='button-img' />
                    {t('APP STORE')}
                  </Button>
                </div>
                <div>
                  <Button variant={variants.WHITE} width='100%'>
                    <img src='/images/home/home/testLightIcon.png' alt='' className='button-img' />
                    {t('TESTFLIGHT')}
                  </Button>
                </div>
              </div>

              <img src='/images/home/home/appStore.png' className='app-item-img' alt='' />
            </div>

            <div className='app-item flex justify-between'>
              <p>{t('Download for Android')}</p>

              <div style={{ width: 175 }}>
                <div>
                  <Button variant={variants.WHITE} width='100%'>
                    <img src='/images/home/home/playStoreIcon.png' alt='' className='button-img' />
                    {t('PLAY STORE')}
                  </Button>
                </div>
                <div>
                  <Button variant={variants.WHITE} width='100%'>
                    <img src='/images/home/home/apkIcon.png' alt='' className='button-img' />
                    {t('APK')}
                  </Button>
                </div>
              </div>

              <img src='/images/home/home/apk.png' className='app-item-img' alt='' />
            </div>
          </div>
        </div>
      </div>

      <div className='bg3'>
        <div className='main chain-wrapper'>
          <div className='title mobile-center'>
            {t('Chain Supported')}
          </div>

          <div className='chain'>
            {Array.from(Array(21).keys()).map(item => (
              <div key={item}>
                <img src={`/images/home/chain/${184 + item}.png`} alt='' />
              </div>
            ))}
          </div>
        </div>

        <div className='main app online'>
          <div className='title mobile-center'>
            {t('Online Token')}
          </div>

          <p
            className='app-desc mobile-center'>{t('Enjoy numerous unique features such as secure wallet, cross-chain swap, send & receive, red packets, and more!')}</p>

          <img src='/images/home/home/Cryptocurrency.png' alt='' />
        </div>
      </div>

      <div className='bg4'>
        <div className='main trusted'>
          <div className='title mobile-center'>
            {t('Trusted Partners')}
          </div>

          <img src='/images/home/home/partners.png' alt='' />
        </div>

        <div className='main security'>
          <div className='title mobile-center'>
            {t('security related partner')}
          </div>

          <div className='flex justify-between items-center flex-wrap'>
            <img src='/images/home/home/partner-1.png' alt='' />
            <img src='/images/home/home/partner-2.png' alt='' />
            <img src='/images/home/home/partner-3.png' alt='' />
          </div>
        </div>

        <div className='main community'>
          <div className='title'>
            {t('Community')}
          </div>

          <div className='news-wrapper'>
            <div className='border-box'>
              <div className='tw-img'>
                <img src='/images/home/home/tw.png' alt='' />
              </div>
              <h3>{t('Twitter')}</h3>

              <div className='pc-show'>
                <Button className='custom' width='150px' marginRight='56px'>{t('EN')}</Button>
                <Button className='custom' width='150px'>{t('CN')}</Button>
              </div>

              <div className='mobile-show' style={{ width: 150, textAlign: 'center' }}>
                <Button className='custom' width='150px' marginBottom='10px'>{t('EN')}</Button>
                <Button className='custom' width='150px' marginBottom='10px'>{t('CN')}</Button>
              </div>
            </div>

            <div className='border-box'>
              <div className='tw-img'>
                <img src='/images/home/home/telegram.png' alt='' />
              </div>
              <h3>{t('Telegram')}</h3>

              <div className='pc-show'>
                <Button className='custom' width='150px' marginRight='33px'>{t('EN')}</Button>
                <Button className='custom' width='150px' marginRight='33px'>{t('CN')}</Button>
                <Button className='custom' width='150px'>NEWS</Button>
              </div>

              <div className='mobile-show' style={{ width: 150, textAlign: 'center' }}>
                <Button className='custom' width='150px' marginBottom='10px'>{t('EN')}</Button>
                <Button className='custom' width='150px' marginBottom='10px'>{t('CN')}</Button>
                <Button className='custom' width='150px' marginBottom='10px'>NEWS</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HomeWrapper>
  )
}

const LargestItem = ({ title, imgUrl, label1Text, label1Icon, label2Text, label2Icon, label3Text, label3Icon }) => {
  const { t } = useTranslation()

  return (
    <div className='largest flex justify-between items-center'>
      <div>
        <h4 className='desc'>{t(title)}</h4>

        <div className='flex items-center'>
          <div className='largest-icon'>
            <img src={label1Icon} alt='' />
          </div>

          <div>{t(label1Text)}</div>
        </div>

        <div className='flex items-center middle-largest'>
          <div className='largest-icon'>
            <img src={label2Icon} alt='' />
          </div>

          <div>{t(label2Text)}</div>
        </div>

        <div className='flex items-center'>
          <div className='largest-icon'>
            <img src={label3Icon} alt='' />
          </div>

          <div>{t(label3Text)}</div>
        </div>
      </div>

      <div className='pc-show'>
        <img src={imgUrl} alt='' />
      </div>
    </div>
  )
}

const HomeWrapper = styled.div`
  background-size: cover;
  font-weight: 400;

  .bg1 {
    background: ${({ theme }) => `url("/images/home/home/home-bg-1${theme.isDark ? '' : '-light'}.png") no-repeat`};
    background-size: 100% 100%;
    overflow: hidden;
  }

  .bg2 {
    background: ${({ theme }) => `url("/images/home/home/home-bg-2${theme.isDark ? '' : '-light'}.png") no-repeat`};
    background-size: 100% 100%;
    padding-top: 200px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding-top: 70px;
    }
  }

  .bg3 {
    background: ${({ theme }) => `url("/images/home/home/home-bg-3${theme.isDark ? '' : '-light'}.png") no-repeat`};
    background-size: 100% 100%;
  }

  .bg4 {
    background: ${({ theme }) => `url("/images/home/home/home-bg-4${theme.isDark ? '' : '-light'}.png") no-repeat`};
    background-size: 100% 100%;

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding-bottom: 25px;
    }
  }

  .mobile-center {
    ${({ theme }) => theme.mediaQueries.mobile} {
      text-align: center;
    }
  }

  .catching {
    display: inline-block;
    word-break: inherit;
    position: relative;
    padding: 0 7px;
    margin: 0 7px;
    color: #fff;

    span {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      height: 38px;
      width: 100%;
      background: linear-gradient(90deg, #17d0ff 0%, #178bff 100%);
      border-radius: 25px;
      position: absolute;
      top: 9.5px;
      left: 0;
      z-index: 1;

      ${({ theme }) => theme.mediaQueries.mobile} {
        height: 20px;
        top: 8px;
      }
    }
  }

  .title {
    font-size: 70px;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.homeTitleColor};
    font-family: "IMPACT";

    ${({ theme }) => theme.mediaQueries.mobile} {
      font-size: 30px;
    }
  }

  .title-color {
    color: ${({ theme }) => theme.colors.homeTitleColor};
  }

  .desc {
    color: ${({ theme }) => theme.colors.homeDescColor};
  }

  .white {
    color: #fff;
  }

  .stop {
    display: flex;
    align-items: center;
    margin-bottom: 350px;
    padding: 80px 0;
    position: relative;

    .simpler {
      margin: 40px 0 30px;
      font-size: 22px;
      font-weight: 400;
    }

    .cross-img {
      width: 768px;
      height: 768px;
      position: absolute;
      top: -50px;
      right: -135px;
    }

    button.custom {
      border-radius: 23px !important;
      font-weight: 400;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      display: block;
      margin-bottom: 20px;
      padding: 20px;

      .simpler {
        margin: 14px 0 15px;
        font-size: 14px;
      }

      button.custom {
        font-size: 12px;
        font-weight: 400;
        padding: 5px 9px;
        border-radius: 11px !important;
        height: auto;
      }

      .cross-img {
        width: 100%;
        height: auto;
        margin: auto;
        position: inherit;
        top: inherit;
        right: inherit;
      }
    }
  }

  .border-box {
    border-radius: 38px;
    position: relative;
  }

  .blockchain {
    margin-top: 60px;
    padding: 30px 0 100px;
    font-size: 22px;
    line-height: 2.5;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.colors.homeDescColor};

    .email-img {
      margin-right: 20px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-top: 0;
      padding: 25px 10px 0;
      margin-bottom: 80px;
      font-size: 14px;

      .email-img {
        width: 110px;
      }
    }
  }

  .out {
    .longdao-app {
      margin-top: 60px;
      padding: 40px 30px;
      display: flex;
      justify-content: space-between;

      &.n1 {
        .rounded-img {
          width: 680px;
          margin-left: 50px;
        }
      }

      &.n2 {
        .rounded-img {
          width: 652px;
          margin-right: 50px;
        }
      }

      &.n3 {
        .rounded-img {
          width: 525px;
          margin-left: 50px;
        }
      }

      h3 {
        font-size: 44px;
        font-family: "IMPACT";
        margin-bottom: 33px;
        font-weight: 400;
      }

      p {
        font-size: 20px;
        line-height: 2;
        margin-bottom: 90px;
        max-width: 630px;
      }

      button {
        font-family: "IMPACT";
        font-size: 32px;
        font-weight: 400;
        padding: 0 48px;
        height: 70px;
      }

      ${({ theme }) => theme.mediaQueries.mobile} {
        margin-top: 18px;
        padding: 20px 0;
        display: block;

        h3 {
          font-size: 22px;
          margin-bottom: 16px;
        }


        &.n1 {
          .rounded-img {
            width: 168px;
            margin: 0;
          }
        }


        &.n2 {
          .rounded-img {
            width: 193px;
            margin: 0;
          }
        }


        &.n3 {
          .rounded-img {
            width: 157px;
            margin: 0;
          }
        }

        p {
          font-size: 14px;
          line-height: 2;
          margin-bottom: 12px;
        }

        button {
          width: 118px;
          font-size: 16px;
          padding: 0;
          height: 35px;
          line-height: 35px;
          border-radius: 7px !important;
        }
      }
    }
  }

  .featured {
    margin-top: 177px;
    margin-bottom: 238px;
    overflow: hidden;
    background: ${({ theme }) => (theme.isDark ? '#0c1480' : 'rgba(178,185,223,0.5)')};

    .main {
      padding: 60px 30px;
      border-radius: 16px;

      h3 {
        font-size: 56px;
        margin-bottom: 75px;
        font-weight: 400;
      }

      .featured-imgs {
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-of-type {
          margin-bottom: 80px;

          div:nth-last-of-type(2) {
            margin: 0 75px;
          }
        }

        &:last-of-type {
          div:first-of-type {
            margin-right: 125px;
          }
        }
      }

      ${({ theme }) => theme.mediaQueries.mobile} {
        padding: 20px;

        h3 {
          font-size: 22px;
          text-align: center;
          margin-bottom: 40px;
        }

        .featured-imgs {
          padding: 0 30px;

          &:first-of-type {
            margin-bottom: 20px;

            div:nth-last-of-type(2) {
              margin: 0 20px;
            }
          }

          &:last-of-type {
            div:first-of-type {
              margin-right: 30px;
            }
          }
        }
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  .news-wrapper {
    padding: 44px 0;
    display: flex;

    .border-box {
      padding: 90px 0 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-right: 34px;
      background: ${({ theme }) => theme.colors.homeBorderBoxBg};

      &:last-of-type {
        margin-right: 0;
      }

      .tw-img {
        width: 118px;
        height: 118px;
        background: #17D0FF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h3 {
        margin: 36px 0 28px;
        font-size: 26px;
        color: ${({ theme }) => theme.colors.homeTitleColor};
      }

      p {
        color: #8e5fac;
        font-size: 18px;
        margin-bottom: 72px;
      }

      button {
        font-family: "IMPACT";
        font-size: 32px;
        font-weight: 400;
        padding: 0 48px;
        height: 70px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 12px 0;

      .border-box {
        margin-right: 8px;
        border-radius: 19px;
        padding: 20px 0 25px;

        .tw-img {
          width: 60px;
          height: 60px;

          img {
            width: 60%;
            height: auto;
          }
        }

        h3 {
          margin: 12px 0 16px;
          font-size: 14px;
        }

        p {
          color: #8e5fac;
          font-size: 12px;
          margin-bottom: 30px;
        }

        button {
          width: 100px;
          font-size: 14px;
          padding: 0;
          height: 35px;
          line-height: 35px;
          border-radius: 7px !important;
        }
      }
    }
  }

  .largest-box {
    .largest {
      color: ${({ theme }) => theme.colors.homeLargestItemColor};
      font-size: 22px;

      .middle-largest {
        margin: 23px 0;
      }

      h4 {
        font-size: 20px;
        max-width: 500px;
        line-height: 2;
        font-weight: 400;
        margin-bottom: 33px;
      }

      .largest-icon {
        width: 45px;
        height: 45px;
        background: #178BFF;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      border-radius: 14px;
      padding: 0 18px;

      &:nth-of-type(2) {
        margin: 80px 0;
      }

      .title {
        font-size: 22px;
      }

      .largest {
        display: block !important;
        font-size: 12px;

        .middle-largest {
          margin: 10px 0;
        }

        h4 {
          font-size: 12px;
          max-width: inherit;
          margin-top: 15px;
          margin-bottom: 15px;
          line-height: 1.5;
        }

        .largest-icon {
          width: 25px;
          height: 25px;
          border-radius: 7px;
          margin-right: 10px;

          img {
            width: 50%;
          }
        }
      }
    }
  }

  .fees {
    margin-top: 73px;
    margin-bottom: 188px;
    padding: 20px 30px;
    background: ${({ theme }) => (theme.isDark ? '#178BFF' : 'rgba(178,185,223,0.5)')};
    border-radius: 38px;
    position: relative;
    height: 288px;

    h2 {
      font-size: 44px;
      font-family: "IMPACT";
      font-weight: 400;
    }

    p {
      max-width: 700px;
      font-weight: 400;
      font-size: 18px;
      margin: 17px 0 50px;
      line-height: 1.5;
    }

    .fees-img {
      position: absolute;
      top: -120px;
      right: 56px;
    }

    button {
      font-family: "IMPACT";
      font-size: 23px;
      font-weight: 400;
      padding: 0 23px;
      height: 47px;
      border-radius: 14px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin: 45px 20px 65px;
      padding: 15px 15px;
      height: auto;
      border-radius: 14px;

      h2 {
        font-size: 22px;
        line-height: 1.2;
      }

      p {
        font-size: 12px;
        margin-bottom: 20px;
      }

      .fees-img {
        bottom: -15px;
        top: inherit;
        right: 10px;
        width: 84px;
      }

      button {
        height: 23px;
        border-radius: 7px !important;
        font-size: 14px;
      }
    }
  }

  .users {
    margin-top: 50px;
    margin-bottom: 200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 39px;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background-color: #F5F5F5;
    }

    .users-item {
      background: #178BFF;
      border-radius: 14px;
      padding: 25px;
      font-weight: 400;
      flex-shrink: 0;
      font-size: 18px;

      div {
        margin-bottom: 50px;
        line-height: 1.4;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin: 17px -20px 50px;
      padding: 0 20px 10px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 0;

      .users-item {
        width: 237px;
        font-size: 13px;
        border-radius: 7px;
        padding: 10px 10px 20px;
        margin-right: 20px;

        div {
          margin-bottom: 20px;
          line-height: 1.2;
        }
      }
    }
  }

  .app {
    padding-bottom: 150px;

    .app-desc {
      max-width: 680px;
      font-size: 18px;
      font-weight: 400;
      margin: 30px 0 50px;
      color: ${({ theme }) => theme.colors.homeDescColor};
      line-height: 1.5;
    }

    .app-item {
      width: 50%;
      height: 167px;
      background: #178BFF;
      border-radius: 14px;
      margin-right: 34px;
      padding: 24px 34px;
      position: relative;
      overflow: hidden;

      .app-item-img {
        position: absolute;
        bottom: -30px;
        left: 90px;
      }

      .button-img {
        position: absolute;
        left: 10px;
      }

      button {
        margin-bottom: 20px;
      }

      p {
        font-size: 30px;
        font-family: "IMPACT";
      }

      button {
        font-family: "IMPACT";
        font-size: 22px;
        font-weight: 400;
        height: 47px;
        border-radius: 14px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding-bottom: 40px;

      .app-desc {
        font-size: 12px;
        margin: 12px 0 16px;
      }

      .app-item {
        height: 129px;
        text-align: center;
        margin-right: 12px;
        padding: 0;
        display: block !important;

        .app-item-img {
          display: none;
        }

        p {
          font-size: 15px;
          margin: 20px 0;
        }

        .button-img {
          width: 12px;
          left: 10px;
        }

        button {
          width: 130px;
          height: 23px;
          border-radius: 7px !important;
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .chain-wrapper {
    padding-top: 60px;

    .chain {
      margin-top: 60px;
      margin-bottom: 200px;
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      gap: 70px;
      background: ${({ theme }) => theme.colors.homeChainBg};
      border-radius: 14px;
      padding: 30px;

      ${({ theme }) => theme.mediaQueries.mobile} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 70px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding-top: 30px;
    }
  }

  .online.app {
    padding-bottom: 20px;
  }

  .trusted {
    margin-bottom: 200px;
    padding-top: 120px;

    .title {
      margin-bottom: 52px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 30px;
      padding-top: 50px;

      .title {
        margin-bottom: 10px;
      }
    }
  }

  .security {
    margin-bottom: 200px;
    border-radius: 38px;
    padding: 46px 0;

    .title {
      margin-bottom: 52px;
    }

    .flex {
      img:nth-of-type(1) {
        height: 58px;
      }

      img:nth-of-type(2) {
        height: 160px;
      }

      img:nth-of-type(3) {
        height: 38px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 30px;
      padding: 20px;

      .title {
        margin-bottom: 10px;
      }

      .flex {
        justify-content: center !important;

        img:nth-of-type(1) {
          height: 25px;
          margin-right: 56px;
        }

        img:nth-of-type(2) {
          height: 50px;
        }

        img:nth-of-type(3) {
          height: 16px;
        }
      }
    }
  }

  .community {
    padding-bottom: 87px;

    .border-box {
      h3 {
        margin-bottom: 50px;
      }

      .tw-img {
        background: #178bff;
      }

      button.custom {
        background: #178bff;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      border-radius: 14px;
      background: ${({ theme }) => theme.colors.homeBorderBoxBg};
      margin: 0 20px;
      padding: 20px 18px;

      .news-wrapper {
        padding-top: 30px;
      }

      .border-box {
        background: transparent;
        box-shadow: none;
        border: none;
        padding: 0;

        .tw-img {
          width: 35px;
          height: 35px;
        }

        h3 {
          margin-bottom: 20px;
        }
      }
    }
  }
`

export default Home
